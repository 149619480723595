import { StaticImage } from "gatsby-plugin-image";
import { shape } from "prop-types";
import React from "react"
import { FiChevronRight } from "react-icons/fi";
import { Container, Section, Text, SuperHeading, Heading, Subhead, SubheadSmall, Flex, Button, Box, PlatformHeading, PlatformSubhead, Link, Head } from "./ui"
import { home_hero_bg, shape_fill, gardient_btns, marginTop_2, home_hero_bg_mobilewraper, paddingTop } from './ui.css';
const Home_hero = () => {

    return (
        <>
            <Section padding={4} background="muted" className={home_hero_bg} >
            </Section>
            <Container width="narrow2" className={marginTop_2}>
                <Flex gap={2} variant="responsive">
                    <Box width="half" >
                        <Head>India’s &nbsp;<StaticImage src="../images/one.png" alt="one" width={70} />
                        </Head>
                        <Head >Online Platform providing</Head>
                        <Head  >Job Experience Digitally.</Head>
                        <Text>The best platform for commerce skilling <br /> & job enhancement. </Text>
                        <Button className={gardient_btns}><Link to="/Login">Start now </Link> <FiChevronRight /></Button>
                    </Box>
                    <Box width="half">
                        <StaticImage src="../images/banner_img_1.png" alt="banner_img_1" style={{ position: "relative", zIndex: "-1" }} />
                    </Box>
                </Flex>
            </Container>


        </>
    )
}
export default Home_hero;