import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import { CARD_DATA, MEGA_PALTFORM_CARDS } from './constants/home_constant_data';
import { bizzlab, spancolor, lightcolor_1, marginBottom, black_1 } from './ui.css'
import { Container, Heading, Section, Flex, Box, Subhead, Text, SubheadSmall, Button, Card, FlexList, MediumBoldText, Megacard } from './ui';
const Mega_platform = () => {
    const data = useStaticQuery(graphql`
    query {
        allImageSharp {
    nodes {
      fixed {
        src
      }
    }
  }
    }
  `)
    const getImageSrc = (nodes, image) => {
        var node = nodes.filter(p => p.fixed.src.includes(image))
        return node[0].fixed.src
    }
    const [platformdata, setPlatformdata] = useState(MEGA_PALTFORM_CARDS)
    return (
        <Section>
            <Container width='narrow2'>
                <Container>
                    <Flex gap={4} variant="responsive" style={{ color: "black" }}>
                        <Box>
                            <Text className={bizzlab}><StaticImage src="../images/king_icon.png" alt="king_icon" width={30} /></Text>
                            <Subhead className={bizzlab}> <StaticImage src="../images/skill_elevator_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} alt="skill_elevator_icon" width={20} style={{ marginTop: "1%" }} /> <span className={spancolor}>Mega Platform </span>for Skill Development</Subhead>
                            <Text className={`${bizzlab} ${lightcolor_1} `}>A Working Platform that offers Job Based Practical Works</Text>
                        </Box>
                    </Flex>
                </Container>
                <Container>
                    <Flex gap={4} variant="responsive">
                        <Container width='narrow2'>
                            <StaticImage src="../images/mega_platform.png" alt="mega_platform" />
                        </Container>
                    </Flex>
                    {/* <img src={getImageSrc(data.allImageSharp.nodes, item.img)} width={30}></img> */}
                            <Flex gap={4} variant="responsive" className={black_1}>
                                <Box>
                                    <Megacard>
                                        <FlexList>
                                            <li>
                                                <Text><StaticImage src='../images/10m_accounting.png' alt='10m_accounting' width={60}/></Text><br/>&nbsp;<br/>&nbsp;<br/>&nbsp;
                                            </li>
                                            <li>
                                                <MediumBoldText >10 million Accounting and other Finance transactions have been enabled</MediumBoldText>
                                                <Text className={lightcolor_1}>Over 10 million accounting, payroll, income tax, GST, compliance, BPO, and other Finance transactions are available to subscribers.</Text>
                                            </li>
                                        </FlexList>
                                    </Megacard>
                                </Box>
                                <Box>
                                    <Megacard>
                                        <FlexList>
                                            <li>
                                                <Text> <StaticImage src='../images/company_transaction.png' alt='company_transaction' width={60}/></Text><br/>&nbsp;<br/>&nbsp;<br/>&nbsp;
                                             
                                            </li>
                                            <li>
                                                <MediumBoldText >Access to 100 different companies' real transactions</MediumBoldText>
                                                <Text className={lightcolor_1}>Transactional bills, vouchers, invoices, and other documents from over 100 different companies are available for practical work.</Text>
                                            </li>
                                        </FlexList>
                                    </Megacard>
                                </Box>
                            </Flex>
                            <Flex gap={4} variant="responsive" className={black_1}>
                                <Box>
                                    <Megacard>
                                        <FlexList>
                                            <li>
                                            <Text> <StaticImage src='../images/practical_working_module.png' alt='10m_accounting' width={60}/></Text><br/>&nbsp;<br/>&nbsp;<br/>&nbsp;
                                             
                                            </li>
                                            <li>
                                                <MediumBoldText >Practical working modules & Job Roles</MediumBoldText>
                                                <Text className={lightcolor_1}>5 different job roles were available in 20 working modules that give subscribers extensive practical knowledge.</Text>
                                            </li>
                                        </FlexList>
                                    </Megacard>
                                </Box>
                                <Box>
                                    <Megacard>
                                        <FlexList>
                                            <li>
                                            <Text> <StaticImage src='../images/company_transaction.png' alt='company_transaction' width={60}/></Text><br/>&nbsp;<br/>&nbsp;<br/>&nbsp;
                                             
                                            </li>
                                            <li>
                                                <MediumBoldText >Access to 100 different companies' real transactions</MediumBoldText>
                                                <Text className={lightcolor_1}>Transactional bills, vouchers, invoices, and other documents from over 100 different companies are available for practical work.</Text>
                                            </li>
                                        </FlexList>
                                    </Megacard>
                                </Box>
                            </Flex>
                </Container>
                <Container>
                    {platformdata.map((item, i) => {
                        return (
                            <Flex gap={4} variant="responsive" style={{ color: "#081F32" }}>
                                <Megacard>
                                    <Flex gap={1} variant="responsive">
                                        <FlexList>
                                            <li>
                                                <img src={getImageSrc(data.allImageSharp.nodes, item.img1)} width={30}></img>
                                            </li>
                                            <li>
                                                <MediumBoldText>{item.title1}</MediumBoldText>
                                            </li>
                                        </FlexList>
                                    </Flex>
                                    <Flex gap={1} variant="responsive">
                                        <FlexList>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li>
                                                <Text className={lightcolor_1}>{item.discription1}
                                                </Text>
                                            </li>
                                        </FlexList>
                                    </Flex>
                                </Megacard>
                                <Megacard>
                                    <Flex gap={4} variant="responsive">
                                        <FlexList>
                                            <li> <img src={getImageSrc(data.allImageSharp.nodes, item.img2)} width={30}></img></li>
                                            <li>
                                                <MediumBoldText>{item.title2}</MediumBoldText>
                                            </li>
                                        </FlexList>
                                    </Flex>
                                    <Flex gap={4} variant="responsive">
                                        <FlexList>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li> <Text className={lightcolor_1}>{item.discription2}
                                                {item.emptyline && <><br />&nbsp;</>}
                                            </Text></li>
                                        </FlexList>
                                    </Flex>
                                </Megacard>
                            </Flex>
                        )
                    })}
                </Container>
            </Container>
        </Section>
    )
}
export default Mega_platform
    ;