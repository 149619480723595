
export const CARD_DATA = [
    {
        img: "10m_accounting",
        title: "10 million Accounting and other Finance transactions have been enabled",
        discription: "Over 10 million accounting, payroll, income tax, GST, compliance, BPO, and other Finance transactions are available to subscribers.",
    },
    {
        img: "company_transaction",
        title: "Access to 100 different companies' real transactions",
        discription: "Transactional bills, vouchers, invoices, and other documents from over 100 different companies are available for practical work.",
    },
    {
        img: "practical_working_module",
        title: "Practical working modules & Job Roles",
        discription: "5 different job roles were available in 20 working modules that give subscribers extensive practical knowledge.",
    },
    {
        img: "practical_working_module",
        title: "Practical working modules & Job Roles",
        discription: "5 different job roles were available in 20 working modules that give subscribers extensive practical knowledge.",
    },
]
export const MEGA_PALTFORM_CARDS = [
    {
        img1: "access_virtual_company",
        title1: "Access to 1 Virtual Company",
        discription1: "Students will be given virtual company login information - Self Learning Modules - Videos and Theory available.",
        img2: "get_certificate",
        title2: "Get Experience Certificate",
        discription2: `On successful completion, a certificate for 1 year of work experience will be provided.`,
        emptyline : true
    },
    {
        img1: "ai_platform",
        title1: "Artificial Intelligence enabled Platform",
        discription1: "Our AI Platform will give various transaction documents on the screen automatically which students need to work on.",
        img2: "verified_cv",
        title2: "Verified & Trained CV",
        discription2: "A resume is automatically created by the platform and titled 'VERIFIED & TRAINED CV' which gives you more weight in the job market.",
    },
    {
        img1: "year_work_experience",
        title1: "Get 1Year equivalent of work experience",
        discription1: "One year of the workload of a medium-sized company will be provided to subscribers.",
        img2: "interviews",
        title2: "Interviews with at least 10 companies",
        discription2: "After completing Practical Works, the platform arranges interviews with a minimum of 10 companies. ",
    },
]
export const MENTOR_DATA=[
    {
       
        data1:"As a mentor, you can induct and enroll your own students in college or tuition or other known networks into Skill-Elevator platform.",
        data2:"Mentors will receive attractive remuneration for their services."
        
    },
    {
        data3:"Platform gives you the opportunity to induct as many of your students as possible.",
        data4:"Mentors must register with the company and complete the registration process with documents.",
    },
    {
        data5:"As a mentor you can track their skill progress from Admission to completion of course, everything through online portal.",
        data6:"Mentors can make a handful of money every month."
    }
]
