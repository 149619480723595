import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { BsCheckCircleFill } from "react-icons/bs";
import { MENTOR_DATA } from './constants/home_constant_data';
import { Container, Heading, Section, Flex, Box, Subhead, Text, SubheadSmall, Button, FlexList,Link } from './ui';
import { FiChevronRight } from "react-icons/fi";
import { bizzlab,black_color,bold,gardient_btns_2,marginBottom,marginTop,marginTop_1,spancolor,margintop13,margintop42 } from './ui.css';
const Mentor_platform = () => {
    const [data, setData] = useState(MENTOR_DATA)
    return (
        <Section >
            <Container className={marginTop_1} width='narrow2'>
                <Container>
                    <Flex gap={4} variant="responsive">
                        <Box>
                            <SubheadSmall center style={{color:"#081F32"}}>Are you a Lecturer / HOD-Commerce / Principal in a college ?</SubheadSmall>
                            <SubheadSmall className={`${bizzlab}  ${bold}`}> JOIN AS<span className={spancolor}> "MENTOR" </span>IN OUR PLATFORM</SubheadSmall>
                        </Box>
                    </Flex>
                </Container>
                <Container>
                    <Flex gap={4} variant="responsive">
                        <StaticImage src="../images/mentor.png" alt="mentor" />
                    </Flex>
                </Container>
                <Container>
                    <Flex style={{ marginLeft: "50%", marginTop: "-25%" }}>
                        <StaticImage src="../images/mentor_screen.png" alt="mentor_screen" />
                    </Flex>
                </Container>
                <Container>
                    <Flex gap={4} variant="responsive" >
                        <Box>
                            <Subhead className={black_color}>Here is the<br /><span style={{ color: "#4367EC" }}>High Remuneration Opportunity</span> for You!</Subhead>
                        </Box>
                    </Flex>
                    <Flex gap={4} variant="responsive" style={{ color: "#53596A" }}>
                        <Box>
                            <FlexList>
                            <li><Text className={`${spancolor} ${margintop42}`}><BsCheckCircleFill /></Text></li>
                                <li><Text>As a mentor, you can induct and enroll your students in college or tuition or other known networks into Skill-Elevator platform.</Text></li>
                            </FlexList>
                        </Box>
                        <Box>
                            <FlexList>
                                <li><Text className={spancolor}> <BsCheckCircleFill /></Text></li>
                                <li><Text>Mentors will receive attractive remuneration for their services.</Text> </li>
                            </FlexList>
                        </Box>
                    </Flex>
                    <Flex gap={4} variant="responsive" style={{ color: "#53596A" }}>
                        <Box>
                            <FlexList>
                                <li><Text className={`${spancolor} ${margintop13}`}><BsCheckCircleFill /></Text></li>
                                <li><Text>Platform gives you the opportunity to induct as many of your students as possible.</Text></li>
                            </FlexList>
                        </Box>
                        <Box>
                            <FlexList>
                                <li> <Text className={`${spancolor} ${margintop13}`}><BsCheckCircleFill /></Text></li>
                                <li><Text>Mentors must register with the company and complete the registration process with documents.</Text> </li>
                            </FlexList>
                        </Box>
                    </Flex>
                    <Flex gap={4} variant="responsive" style={{ color: "#53596A" }}>
                        <Box>
                            <FlexList >
                                <li><Text className={`${spancolor} ${margintop42}`}><BsCheckCircleFill /></Text></li>
                                <li><Text>As a mentor, you can track their skill progress from Admission to completion of the course, everything through the online portal.</Text></li>
                            </FlexList>
                        </Box>
                        <Box>
                            <FlexList>
                                <li><Text className={spancolor}><BsCheckCircleFill /></Text></li>
                                <li><Text>Mentors can make a handful of money every month.</Text> </li>
                            </FlexList>
                        </Box>
                    </Flex>
                   <Text> <Button  className={`${gardient_btns_2} ${marginTop}`}><Link to='/mentor'>View Details </Link><FiChevronRight /></Button></Text>
                </Container>
            </Container>
        </Section>
    )
}
export default Mentor_platform;