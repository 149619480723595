import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
// import Business_labratory from './home_level1';
import { Container, Heading, Section, Flex, Box, Subhead, Text, SubheadSmall, Button, FlexList } from './ui';
import { bizzlab, handson_experience, spancolor, text_color, bizzlab_btn, unique_img, lightcolor, marginBottom_5, bold, marginTop,  } from './ui.css'
import { FiArrowUpRight, } from "react-icons/fi";
const Home_unique_concept = () => {
    return (

        <Section>
            <Container width='narrow2' >

                <Text className={bizzlab} ><Button className={bizzlab_btn}>BIZZ LAB</Button></Text>
                <Subhead className={bizzlab}>Business Laboratory</Subhead>
                <Text className={`${bizzlab} ${lightcolor}`}>A Comprehensive web platform to virtually experiment and learn various accounting, finance and tax works.</Text>


                <Container >
                    <Flex gap={2} variant="responsive">
                        <Box >
                            <FlexList className={text_color}>
                                <li><Text><StaticImage src="../images/practical.png" alt="number_icon1" width={30} /></Text></li>
                                <li> <Text className={bold}>Practical Training software for Accounting and Tax</Text></li>
                            </FlexList>
                            <FlexList className={text_color}>
                                <li> <Text><StaticImage src="../images/simulated_work_experience.png" alt="simulated_work_experience" width={30} /></Text></li>
                                <li> <Text className={bold}>Simulated work experience in commerce</Text></li>
                            </FlexList>
                            <FlexList className={text_color}>
                                <li> <Text><StaticImage src="../images/virtual_entry.png" alt="virtual_entry" width={30} /></Text> </li>
                                <li ><Text className={bold}>Virtual entity concept to gain work experience</Text></li>
                            </FlexList>
                            <FlexList className={text_color}>
                                <li> <Text><StaticImage src="../images/digital_platform.png" alt="digital_platform" width={30} /> </Text></li>
                                <li > <Text className={bold}>A digital platform for skill education</Text></li>
                            </FlexList>

                            <FlexList className={text_color}>
                                <li><Text><StaticImage src="../images/advanced_web_application.png" alt="advanced_web_application" width={30} /></Text> </li>
                                <li ><Text className={bold}>Advanced web application that simulate industry work in virtual environment</Text></li>
                            </FlexList>
                            <Flex gap={3} variant="responsive">
                                <Box>
                                    <StaticImage src="../images/college_girl.png" alt="college_girl" width={1024} />
                                </Box>
                                <Box >
                                    <Text className={handson_experience}>Looking for<span className={spancolor}> hands-on work experience </span>in Accounting, Tax & Finance field?</Text>
                                    <Button style={{ whiteSpace: "nowrap" }} className={marginBottom_5}>Register & Work in our Online Platform <FiArrowUpRight /></Button>
                                </Box>
                            </Flex>
                        </Box>

                        <Box className={unique_img}>
                            <StaticImage src="../images/unique_concept.png" alt="unique_concept" />
                        </Box>

                    </Flex>
                </Container>




            </Container>
        </Section>
    )
}
export default Home_unique_concept;