import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
// import { AiOutlineArrowRight } from "@react-icons/all-files/fa/";
import { AiOutlineArrowRight } from "react-icons/ai";
import { BsCheckCircleFill } from "react-icons/bs";
import { FcApproval } from "react-icons/fc";
import { FiChevronRight } from "react-icons/fi";
import { Container, Heading, Section, Flex, Box, Subhead, Text, SubheadSmall, Button,FlexList,Link } from './ui';
import{bold, margin, marginBottom, marginBottom_2, marginTop, spancolor_5,spancolor_6} from './ui.css'
const Attention_collage = () => {
    return (
        <Section style={{background: "#0F054C", transform:"skewY(-5deg)",color:"#fff"}}>
        <Container style={{ transform:"skewY(5deg)"}} width='narrow2'>
         
                <Flex  gap={4} variant="responsive">
               
                    <Box>
            <Flex gap={4} variant="responsive" className={marginTop}>
                <Box>
                <Text style={{color:"#FFDD75"}} className={bold}>ATTENTION TO COLLEGES !!</Text>
                <Subhead className={marginBottom_2}>Do you have <br/>Business Laboratory<br/> in your college?	</Subhead>
                <FlexList>
                        <li><SubheadSmall> <span className={spancolor_5}>We help you to set-up</span></SubheadSmall></li>
                    </FlexList>
                    <FlexList>
                        <li> <Text><BsCheckCircleFill className={spancolor_5}/></Text></li>
                        <li><Text className={spancolor_6}>Impressive Platform for BUSINESS LABORATORY (BizzLab) in your commerce wing.</Text></li>
                    </FlexList>
                    <FlexList>
                        <li> <Text><BsCheckCircleFill className={spancolor_5}/></Text></li>
                        <li> <Text className={spancolor_6}> Industry based training to your student through BizzLab.</Text></li>
                    </FlexList>
                    <FlexList>
                        <li> <Text><BsCheckCircleFill className={spancolor_5}/></Text></li>
                        <li> <Text className={spancolor_6}>We implement SKILL AND EMPLOYABILITY ENHANCEMENT concept for your commerce student in your Colleges.</Text></li>
                    </FlexList>
                    <Button className={marginTop}><Link to="/associates">View Details </Link><FiChevronRight /></Button>
                </Box>        
            </Flex>
                    </Box>
                 <Box> <StaticImage src="../images/business_laboratory.png" alt="business_laboratory" width={500}  /></Box>
                </Flex>
            </Container>
            
        </Section>
    )
}
export default Attention_collage;