import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Home_hero from "../components/Home_hero"
import Home_Digital_platform from "../components/home_Digital_platform"
import Home_Challanges from "../components/home_challanges"
import Home_unique_concept from "../components/home_hand_son_experience"
import Skill_elevator_platform from "../components/home_skill_elevator_platform"
import Background_linear from "../components/home_background_linear"
import Business_labratory from "../components/home_level1"
import Job_simulator from "../components/home_leve2"
import Background_white from "../components/home_background_white"
import Mega_platform from "../components/home_mega_platform"
import Who_registar from "../components/home_who_registar"
import Who_can_associate from "../components/home_who_associate"
import Attention_collage from "../components/home_attention"
import Mentor_platform from "../components/home_mentor_platform"
import ContactUS from "../components/contact-us"

const IndexPage = (location) => (
  <Layout navName="Home" location={location}>
    <Seo title="Home" />
    <Home_hero />
    <Home_Digital_platform />
    <Home_Challanges />
    <Home_unique_concept />
   
    <Business_labratory/>
    
    <Job_simulator/>
    <Mega_platform/>
    <Who_registar/>
    <Who_can_associate/>
    <Attention_collage/>
    <Mentor_platform/>
    <ContactUS />
  </Layout>
)

export default IndexPage
