import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { FiChevronRight } from "react-icons/fi";
import { BsCheck } from "react-icons/bs";
import { Container, Heading, Section, Flex, Box, Subhead, Text, SubheadSmall, Button, FlexList,Link } from './ui';
import { blackwhit_btn, Who_registar_bg, border,spancolor_1,Italic,spancolor,marginTop,fontsize18,gardient_btns_1 } from './ui.css'
const Who_registar = () => {
    return (
        <div className={Who_registar_bg}>
            <Container style={{ transform: "skewY(5deg)" }}>
                <Container width='narrow2'>
                    <Flex gap={4} variant="responsive">
                        <Box>

                            <Heading> <span className={spancolor_1}>Who</span> <StaticImage src="../images/skill_elevator_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} alt="skill_elevator_icon" width={20} style={{ marginTop: "4%" }} /><br />Can Register</Heading>
                            <Text className={Italic}><sapn className={spancolor_1}>Studies + Industry work experience</sapn> = Sucess in Career</Text>

                            <Flex gap={4} variant="responsive" className={marginTop}>
                                <Box>

                                    <Button variant='blackwhite' className={blackwhit_btn}> <BsCheck className={spancolor}/>  &nbsp;Under Graduates</Button>
                                    <Button variant='blackwhite' className={blackwhit_btn}> <BsCheck className={spancolor} /> &nbsp;Graduates</Button>
                                    <Button variant='blackwhite' className={blackwhit_btn}> <BsCheck className={spancolor} /> &nbsp;Post Graduates</Button>
                                    <Button variant='blackwhite' className={blackwhit_btn}> <BsCheck className={spancolor} /> &nbsp;Professional Studies</Button>
                                    <Button variant='blackwhite' className={blackwhit_btn}> <BsCheck className={spancolor} /> &nbsp;Job Seekers </Button>
                                </Box>

                                <Box>
                                    <FlexList >
                                        <li>
                                            <Text className={border}></Text>
                                        </li>
                                      <li> </li>
                                      <li> </li>
                                     
                                        <li>
                                            <Text style={{position:"relative",bottom:"43px"}}>B.Com, BBA, BBM</Text>
                                            <Text></Text>
                                            <Text >M.Com, MBA (finance)</Text>

                                            <Text></Text>
                                            <Text style={{position:"relative",top:"43px"}}>CA, CS, CFA, CMA</Text>
                                        </li>
                                    </FlexList>

                                </Box>
                            </Flex>

                            <Section>
                                <Flex>
                                    <SubheadSmall className={`${spancolor_1} ${fontsize18}`}><StaticImage src="../images/skill_elevator_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} alt="skill_elevator_icon" width={20} style={{marginTop:"1%"}}/> &nbsp;You aren’t a fresher anymore once you’re Graduated!</SubheadSmall>

                                    
                                </Flex>
                                <Button className={gardient_btns_1}><Link to="/Login">Register for Free</Link><FiChevronRight /></Button>
                            </Section>
                        </Box>
                        <Box>
                            <StaticImage src="../images/who_can_register.png" alt="who_can_register"  />
                        </Box>
                    </Flex>
                </Container>


            </Container>
        </div>
    )
}
export default Who_registar;