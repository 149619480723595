import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { FiChevronRight } from "react-icons/fi";
import { Container, Heading, Section, Flex, Box, Subhead, Text, SubheadSmall, Button, Card, Megacard, Link } from './ui';
import { card_college, card_bordy, Who_can_associate_bg, spancolor, associate_card, lightcolor, margin_card,card_pading, fontsize25, gardient_btns_2, spancolor_4, marginBottom,marginBottom_1 } from './ui.css'
const Who_can_associate = () => {
    return (
        <Section className={Who_can_associate_bg}>
            <Container style={{ transform: "skewY(5deg)", color: "#081F32" }} width="narrow2">
            <Flex gap={4} variant="responsive" >
                    <Box >
                        <Subhead className={fontsize25}> Who <StaticImage src="../images/skill_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} alt="skill_elevator_icon" width={30} style={{ marginTop: "2%" }} /><br /><span className={spancolor}>Can Associate</span></Subhead>
                        <Text className={spancolor_4}>For Skill & Employment enhancement</Text>
                    </Box>
                </Flex>
            </Container>
            <Container style={{ transform: "skewY(5deg)", color: "#081F32" }} width="tight2" >
               
                <Flex gap={5} variant="responsive" >
                    <Box>
                        <Card className={associate_card} >
                            <StaticImage src="../images/college.png" alt="colleges" />
                            <div className={card_bordy}>
                                <Text className={card_college}>COLLEGES</Text>
                                <Text  className={card_pading}>Can collaborate with Skill Elevator to set up Business Laboratory in their colleges for commerce students.  </Text><br/>&nbsp;
                                <Button className={`${gardient_btns_2} ${marginBottom_1} ${card_pading}`}><Link to="/associates">View Details </Link><FiChevronRight /></Button>
                            </div>
                        </Card>
                    </Box>
                    <Box>
                        <Card className={associate_card}>
                            <StaticImage src="../images/corporate.png" alt="corporate" />
                            <div className={card_bordy}>
                                <Text className={card_college}>CORPORATES</Text>
                                <Text className={card_pading}>Any commerce faculty, lectures, HODs & principals can enroll with us as a mentor and earn a Handful<br/> of remuneration. </Text>
                                <Button className={`${gardient_btns_2} ${marginBottom_1} ${card_pading}`}><Link to="/associates">View Details </Link><FiChevronRight /></Button>
                            </div>
                        </Card>
                    </Box>
                </Flex>
            </Container>
        </Section>
    )
}
export default Who_can_associate;