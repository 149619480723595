import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { FiChevronRight } from "react-icons/fi";
import { Container, Heading, Section, Flex, Box, Subhead, Text, SubheadSmall, Button, Link } from './ui';
import { spancolor, level1_btn,spancolor_2,fontsize14,fontsize18, lightcolor } from './ui.css';
const Job_simulator = () => {
    return (
        <>
        <Section style={{ background: "#0F054C", transform: "skewY(-5deg)", color: "#fff",marginTop:"3%" }}>
            <Container style={{ transform: "skewY(5deg)" }} width='narrow2'>
                <Container>
                    <Flex gap={4} variant="responsive">
                        <Box>
                            <Text className={spancolor_2}>LEVEL 2</Text>
                            <Subhead>5 - Job Role Based Modules</Subhead>
                            <Text className={lightcolor}>Simulated Experience of various Finance & Accounting &nbsp;Job roles in Industry</Text>
                            <Button className={level1_btn}><Link to="/platform">Read More</Link> <FiChevronRight /></Button>
                        </Box>
                        <Box>
                            <StaticImage src="../images/josim_lap.png" alt="josim_lap" />
                        </Box>
                    </Flex>
                </Container>
                <Container>
                    <Flex gap={4} variant="responsive">
                        <Box>
                            <Text>  <StaticImage src="../images/work_virtual_company.png" alt="virtual company icon" width={50} /></Text>
                            <Text className={fontsize14}>5 On job roles/ <br/>positions<br/>&nbsp;</Text>
                            
                        </Box>
                        <Box>
                            <Text>    <StaticImage src="../images/king_icon.png" alt="king icon" width={40} /></Text>
                            <Text className={fontsize14}>Get access to job positions <br/> in  virtual company to work<br/> &nbsp;</Text>
                        </Box>
                        <Box>
                            <Text>  <StaticImage src="../images/get_certificate.png" alt="get_certificate" width={35} /></Text>
                            <Text className={fontsize14}>Get 3 years job training experience <br/>certificate with Pro Finance<br/> Manager Qualification</Text>
                        </Box>
                        <Box></Box>
                    </Flex>
                </Container>
                <Container>
                    <Flex>
                        <Text className={fontsize18}  style={{marginTop:"5%"}}><StaticImage src="../images/skill_elevator_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} quality={95} formats={["auto", "webp", "avif"]} alt="skill_elevator_icon" width={15} style={{ marginTop: "2.5%" }} /> Learn all job related works before you join the job</Text>
                    </Flex>
                </Container>
            </Container>
        </Section>
       
        </>

    )
}
export default Job_simulator;