import { StaticImage } from 'gatsby-plugin-image';
import React, { useState, useEffect } from 'react';
import { FiChevronRight } from "react-icons/fi";
import { Container, Heading, Section, Flex, Box, Subhead, Text, SubheadSmall, Button, Link } from './ui';
import { level1_btn, spancolor,lightcolor, spancolor_2 ,fontsize14,fontsize18} from './ui.css'
const Business_labratory = () => {


    return (
        <>
        <Section style={{ background: "#0F054C", transform: "skewY(-5deg)", color: "#fff",marginTop:"-15%" }}>
            <Container style={{ transform: "skewY(5deg)" }} width='narrow2'>
                <Container>
                    <Flex gap={4} variant="responsive">
                        <Box>
                            <Text className={spancolor_2}>LEVEL 1</Text>
                            <Subhead >20 - Working Modules</Subhead>
                            <Text className={lightcolor}>Provides virtual Experience of various Accounting and Tax works.</Text>
                            <Button className={level1_btn}>  <Link to="/platform">Read More </Link><FiChevronRight /></Button>
                        </Box>
                        <Box>
                            <StaticImage src="../images/Bizlab_lap.png" alt="Bizlab_lap" />
                        </Box>
                    </Flex>
                </Container>
                <Container>
                    <Flex gap={4} variant="responsive">
                        <Box>
                            <Text>  <StaticImage src="../images/work_virtual_company.png" alt="virtual company icon" width={50} /></Text>
                            <Text className={fontsize14}>20 in-depth <br/>practical working modules</Text>
                        </Box>
                        <Box>
                            <Text><StaticImage src="../images/king_icon.png" alt="king icon" width={35} /></Text>
                            <Text></Text>
                            <Text className={fontsize14}>Access to 1 virtual company <br />to work</Text>
                        </Box>
                        <Box>
                            <Text> <StaticImage src="../images/get_certificate.png" alt="get_certificate" width={30} /></Text>
                            <Text className={fontsize14}>Experience certificate with <br/>Pro-Accountant Qualification</Text>
                        </Box>
                        <Box>

                        </Box>
                    </Flex>
                </Container>
                <Container>
                    <Flex>
                        <Text className={fontsize18} style={{marginTop:"5%"}}><StaticImage src="../images/skill_elevator_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} alt="skill_elevator_icon" width={15} style={{ marginTop: "2%" }} /> Gain Accounting & Commerce experience along with studies</Text>
                    </Flex>
                </Container>
            </Container>
        </Section>
       
         </>
    )
}
export default Business_labratory;