import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { AiOutlineArrowRight } from "react-icons/ai";
import { Container, Heading, Section, Flex, Box, Subhead, Text, SubheadSmall, Button, Link } from './ui';
import { digital_patform, marginTop, marginTop_1, spancolor, spancolor_2, spancolor_3 } from './ui.css'
const Home_Digital_platform = () => {
    return (

        <Section >
            <Container className={marginTop_1} width='narrow2'>
                <Flex gap={4}  >
        
                    <Box>  <StaticImage src="../images/dpiit.png" alt="dpiit" height={70}/> </Box>
                    <Box>  <StaticImage src="../images/iso.png" alt="iso" height={70} /> </Box>
                    <Box>  <StaticImage src="../images/skill_india.png" alt="skill_india" height={70}/> </Box>
                    <Box>  <StaticImage src="../images/startup_india.png" alt="sartup_india" height={70} /> </Box>
                    <Box>  <StaticImage src="../images/national_corporation.png" alt="national_corporation" height={70}/> </Box>

                </Flex>
            </Container>
            <Container className={digital_patform} width='narrow2'>
                <Box style={{ color: "#081F32" }}>
                    <Text className={spancolor_3}> <StaticImage src="../images/skill_elevator_icon.png" alt="skill_elevator_icon" width={16} style={{ marginTop: "0.5%" }} />&nbsp;WELCOME TO BIZZLAB</Text>
                    <Subhead>A Digital Platform for <span className={spancolor}>Skill</span> and <br /> <span className={spancolor}>Employment Enhancement.</span></Subhead>
                    <Text>Bizzlab is India's first web platform to provide complete Industry and Job experience in a virtual environment in the accounting and finance field. Under this platform, all required accounting, tax, statutory filing, and other business works have been created as practical modules. Actual bills, transactions, filings, procedural documents, etc are going to be used while working in our modules.<span style={{ color: "#1C97EF", fontWeight: "bold", fontStyle: "italic" }}><Link to="/about"> Read more..</Link></span></Text>
                </Box>
            </Container>
            <Container width='narrow2'>
                <Box>
                    <Container className={marginTop_1}>
                        <StaticImage src="../images/score_card.png" alt="score card" />
                    </Container>

                </Box>
            </Container>
        </Section>
    )
}
export default Home_Digital_platform