import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Container, Heading, Section, Flex, Box, Subhead, Text, SubheadSmall, FlexList } from './ui';
import { textdata, text_title, box_space, challanage_gap, spancolor, spancolor_1 } from './ui.css'
const Home_Challanges = () => {
    return (
        <Section style={{ background: "radial-gradient(88.77% 152.19% at 12.8% -49.02%, #2B237C 0%, #251C72 31%, #0F054C 100%)", color: "#fff", transform: "skewY(-6deg)", paddingTop: "6%" }}>
            <Container style={{ transform: "skewY(6deg)" }}>
                <Container>
                    <p>
                        <Flex gap={4} variant="responsive">
                            <Box>
                                <Subhead> <span className={spancolor_1}>Challenges faced</span> <StaticImage src="../images/skill_icon.png" transformOptions={{ fit: "cover", cropFocus: "attention" }} alt="skill_elevator_icon" width={20} style={{ marginTop: "1%" }} /> <br /> by Commerce Students, Colleges And Industry</Subhead>

                            </Box>
                        </Flex>
                    </p>
                </Container>
                <Container className={challanage_gap}>
                    <Flex gap={4} variant="responsive" >
                        <Box>
                            <FlexList gap={4}  >
                                <li> <Text><StaticImage src="../images/no_practical_training.png" alt="practical_training" /></Text></li>
                                <li>
                                    <Text className={text_title}>No Practical Training</Text>
                                    <Text className={textdata}>Commerce students do not have exposure to practical training in the colleges
                                    </Text></li>
                            </FlexList>
                        </Box>

                        <Box>
                            <FlexList gap={4}>
                                <li>  <Text><StaticImage src="../images/time_cost.png" alt="time_cost" /></Text></li>
                                <li> <Text className={text_title}>Time & Cost Factor</Text>
                                    <Text className={textdata}>For Corporates & CA firms, providing hands-on job training taking more time and cost</Text></li>
                            </FlexList>
                        </Box>
                    </Flex>
                </Container>
                <Container className={challanage_gap}>
                    <Flex gap={4}  variant="responsive"  >
                        <Box>
                            <FlexList gap={4} >
                                <li> <Text><StaticImage src="../images/not_well_equiped.png" alt="not_well_eqiped" width={200} /></Text></li>
                                <li><Text className={text_title}>Colleges are not well equiped</Text>
                                    <Text className={textdata}>Colleges are not able to design a practical working lab for commerce and business students</Text></li>
                            </FlexList>
                        </Box>

                        <Box>
                            <FlexList gap={4}  >
                                <li> <Text> <StaticImage src="../images/learning_curve.png" alt="learning_curve" width={200} /></Text></li>
                                <li> <Text className={text_title}>Steep Learning Curve</Text>
                                    <Text className={textdata}>Fast track in career has become challenging without a proper fast track training platforms</Text></li>
                            </FlexList>
                        </Box>
                    </Flex>
                </Container>
                <Container className={challanage_gap}>

                    <Flex gap={4} variant="responsive" >
                        <Box>
                            <FlexList gap={4}  >
                                <li> <Text><StaticImage src="../images/over_looked.png" alt="over_looked" width={250} /></Text></li>
                                <li><Text className={text_title}>Freshers are Over-looked</Text>
                                    <Text className={textdata}>Most of the companies are looking for experienced commerce students and thereby freshers are overlooked</Text></li>
                            </FlexList>
                        </Box>

                        <Box>
                            <FlexList gap={4}  >
                                <li> <Text> <StaticImage src="../images/learning_resource.png" alt="learning_resource" width={250} /></Text></li>
                                <li> <Text className={text_title}>No Standard Learning Source</Text>
                                    <Text className={textdata}>There is no product in the world which can simulate the actual industry works for students and corporates</Text></li>
                            </FlexList>
                        </Box>
                    </Flex>
                </Container>
            </Container>
        </Section>
    )
}
export default Home_Challanges;